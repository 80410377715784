/* :root {
  --video-width: '90vw' !important;
  --video-height: 'auto' !important;
}

#video-wrapper {
  width: var(--video-width) !important;
  height: var(--video-height) !important;
} */

/* .tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
} */

.tooltiptext {
  visibility: hidden;
  /* width: 120px; */
  max-width: 400px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  /* bottom: 15%; */
  /* left: 30%; */
  /* margin-left: -60px; */
  margin-top: 50px;
  opacity: 0;
  transition: opacity 0.3s;
  height: 0;
}

/* .tooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
} */

.tooltiptext.show {
  height: auto;
  visibility: visible;
  opacity: 1;
}

#video-wrapper>video {
  width: 98vw;
  height: 'auto';
  max-height: 80vh;
  justify-self: center;
  align-self: center;
}

.plan-features-text-lg {
  font-size: 30px !important;
}


@media screen and (max-width: 479px) {
  .plan-features-text-lg {
    font-size: 6vw !important;
  }
}

/* 
@media screen and (max-width: 1400px) {
  :root {

    --video-width: 'auto' !important;
    --video-height: '480px' !important;
  }
}


@media screen and (max-width: 900px) {
  :root {

    --video-width: 'auto' !important;
    --video-height: '600px' !important;
  }
} */


.table-responsive {
  max-height: 80vh;
}