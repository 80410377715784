@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;700&display=swap');

body {
    background-color: rgba(180, 180, 224, 0.4) !important;
    font-family: 'Montserrat', sans-serif;
    color: rgb(51, 62, 81);
    ;
    margin: 0 !important;
    padding-top: 80px;
}

label {
    font-weight: bolder;
}

input[type='text'],
select,
input[type='email'] {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    border: 1px solid rgba(51, 62, 81, 0.6);
    height: 40px;
    padding: 8px;
}


input[type=text]:focus,
input[type=email]:focus,
select:focus,
textarea:focus {
    box-shadow: 0 0 5pt 2pt rgba(51, 62, 81, 0.6);
    border: 1px solid rgba(51, 62, 81, 0.6);
    outline-width: 0px;
}

input[type=text]::placeholder,
input[type=email]::placeholder,
textarea::placeholder {
    opacity: 0.8;

}



textarea {
    font-family: 'Montserrat', sans-serif;
    border: 1px solid rgba(51, 62, 81, 0.6);
    padding: 10px;
}



.header {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    padding: 0 30px;
    margin: 50px 100px;
}

.header-heading {
    text-align: center;
    margin-bottom: 50px;
}

.form-style {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    background-color: white;
    border-radius: 15px;
    padding: 50px;
    margin: 0 100px 30px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
}

.film-information-header {
    font-family: 'Montserrat', sans-serif;
    text-align: center;
    margin-bottom: 30px;
}

.form-input {
    font-family: 'Montserrat', sans-serif;
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: flex-start;
    justify-content: flex-start;
    border: none;
    outline-width: 0;
    box-shadow: none;
}

.form-input-label {
    width: 25%;
    border: none;
}

.input-styling {
    font-family: 'Montserrat', sans-serif;
    width: 100%;
}

.options {
    font-family: 'Montserrat', sans-serif;
    display: inline-block;
    width: 90%;
}

.button-wrapper {
    text-align: right;
}

.form-button {
    font-family: 'Montserrat', sans-serif;
    width: 200px;
    padding: 10px 0;
    background-color: rgb(51, 62, 81);
    color: white;
    font-weight: 400;
    margin-top: 30px;
}

.form-button:hover {
    cursor: pointer;
}

.upload-video {
    background-color: rgb(51, 62, 81);
    ;
    color: white;
    font-weight: 600;
    padding: 5px 10px;
}

.upload-video:hover, .modal-button:hover {
    cursor: pointer;
}

.modal {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
    background: rgba(0, 0, 0, 0.6) !important;
}

.modal-main {
    text-align: center !important;
    position: fixed !important;
    background: white !important;
    width: 50% !important;
    height: auto !important;
    padding: 30px 100px !important;
    border-radius: 15px !important;
    top: 50% !important;
    left: 50% !important;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
    transform: translate(-50%, -50%) !important;
}

@media screen and ( max-width : 500px )  {
    .modal-main {
        width: 80% !important;
    }
}

.modal-button, .reset-file-input{
    background-color: rgb(51, 62, 81);
    color: white;
    font-weight: 600;
    padding: 5px 10px
}

.featured-container{
    width: 80% !important;
    padding: 0;
    background-color: rgb(51, 62, 81);
    box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px !important;
    margin-bottom: 30px;
}

.movie-card{
    background-color: rgb(174, 177, 182) !important;    
    width: 80% !important;
    height: 100%;
    margin: auto;
    display: flex !important;
    flex-direction: row !important;
    flex-wrap: nowrap !important;
    


}
.mov-info-sec{
    width: 55% !important;
    height: 100%;
    padding-left: 10px;
    padding-right: 20px;
    padding-bottom: 0;
}
.row-custom-style{
    text-align: center;
    flex-wrap: nowrap !important;
}
.display-block {
    display: block !important; 
}

.display-none {
    display: none !important;
}
.rights-agreement{
    color: red;
}

.carousel {
    width : 100% !important;
}
.carousel-indicators [data-bs-target] {
    width: 10px !important;
    height: 10px !important;
    border: 1px solid transparent !important;
    border-radius: 10px;
  }

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 300px;
  /* Jerry Changes */
  width: 5%;
  max-width: 300px;
  /* width: 300px; */
  background-size: 100%, 100%;
  border-radius: 50%;
  padding: min( 50%, 20px );
  
}
  
.carousel-control-prev,
.carousel-control-next{
    background-color: rgb(51, 62, 81) !important; 
    opacity: 1 !important;
    margin: 0 !important;
     /* Jerry Changes */
    width: min(10%, 120px) !important;
    max-width: 120px !important;
    /* width: 120px !important; */
}

@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .spinner-container{
    position: relative;
    height: 100vh;
  }
  .spinner-container h3{
    position: absolute;
    top: 50%;
    left: 48.5%;
  }
  .loading-spinner {
    position: absolute;
    top: 40%;
    left:50%;
    width: 50px;
    height: 50px;
    margin: auto 0;
    border: 10px solid #f3f3f3; /* Light grey */
    border-top: 10px solid #383636; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

@media screen and (max-width: 1024px) {
    .form-input {
        flex-wrap: wrap;
        gap: 0
    }

    .form-input-label {
        width: 100%;
    }

    .form-style {
        margin: 0 30px 30px;
    }

    .header {
        margin: 50px 20px;
    }

    .film-information-header {
        font-size: 20px;
    }
}